
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.SplitPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include mq($breakpoint-desktop) {
    flex-direction: row;
  }
}

.SplitPagePrimary {
  position: relative;
  background: $color-blue;
  color: $color-white;
  background-image: linear-gradient(220deg, $color-lightblue-l50, $color-blue);
  padding: spacing(4);

  @include mq($breakpoint-desktop) {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 15% 0;
    background-repeat: no-repeat;
    background-image: url('/image/bg-dashboard.png');
    background-size: cover;

    @include mq($breakpoint-desktop) {
      background-size: auto 100%;
    }
  }

  > * {
    position: relative;
  }

  > * + * {
    margin-top: spacing(4);
  }
}

.SplitPageHeader {
  @include mq($max: $breakpoint-desktop - 1) {
    display: flex;
    justify-content: center;
  }
}

.SplitPageIntro {
  max-width: spacing(72);
  margin-left: auto;
  margin-right: auto;
  @include font-smoothing;

  @include mq($breakpoint-desktop) {
    max-width: spacing(48);
  }
}

.SplitPageFooter {
  max-width: spacing(72);
  margin-left: auto;
  margin-right: auto;

  @include mq($breakpoint-desktop) {
    text-align: right;
    margin-right: 0;
    max-width: none;
  }

  * {
    color: $color-white !important;
  }
}

.SplitPageSecondary {
  background-color: $color-coolgrey-l98;
  flex-grow: 1;

  @include mq($breakpoint-desktop) {
    flex: 1 1 60%;
    display: flex;
    flex-direction: column;
  }
}

.SplitPageContent {
  padding-top: spacing(4);
  padding-bottom: spacing(4);

  @include mq($breakpoint-desktop) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
